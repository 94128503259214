/**
 * 手机号登录
 */
export class LoginForm {
  tel = ''

  passwd = ''

  tel_code = ''

  type = 0 // 0=验证码登录; 1=密码登录

  'device_tokens' = ''

  'device_type' = 0

  /**
   * 表单校验
   * @return Boolean
   */
  invalid() {
    // 手机号
    const isInvalidPhone = this.invalidPhone();
    if (isInvalidPhone) {
      return isInvalidPhone;
    }
    if (this.type === 1 && !this.passwd) return '请输入密码';
    if (this.type === 1 && this.passwd.length < 6) return '密码不少于6位';
    if (this.type === 0 && !this.tel_code) return '请输入验证码';
    if (this.type === 0 && this.tel_code.length < 4) return '请输入正确的验证码';
    return false;
  }

  // 手机号校验
  invalidPhone() {
    if (!this.tel) {
      return '请填写手机号';
    }
    if (this.tel.length < 11) {
      return '请填写正确的手机号';
    }
    return false;
  }

  // 重设手机号
  resetPhone() {
    this.tel = '';
    this.resetPassword();
  }

  // 重设密码
  resetPassword() {
    this.passwd = '';
  }

  /**
   * 转给服务端的参数
   * @return Object
   */
  toObject() {
    return JSON.parse(JSON.stringify(this));
  }
}

export default LoginForm;

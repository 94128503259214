/**
 * 登录业务处理
 * @author  Jianbo
 * @time 2020-03-05
 */
import LoginService from '../service/LoginService';
import LoginByPhoneForm from '../form/account/LoginByPhoneForm';
import { TokenStorageFactory } from './TokenStorageFactory';
import { AccountFactory } from './AccountFactory';

import { isIOS } from '../utils/device';

const WFC_ACCOUNT_PHONE_KEY = 'WFC_ACCOUNT_PHONE_KEY';

export class LoginFactory {
  /**
   * 手机号密码登录表单生成
   * @return object LoginByPhoneForm
   */
  static createLoginByPhoneForm() {
    const form = new LoginByPhoneForm();
    // 设置当前设备类型
    const phone = LoginFactory.getLocalStoragePhone() || '';

    form.deviceType = isIOS() ? 2 : 1;
    form.tel = phone;
    return form;
  }

  /**
   * 手机号保存到本地
   * @param  string phone
   */
  static storePhone(phone) {
    window.localStorage.setItem(WFC_ACCOUNT_PHONE_KEY, phone);
  }

  /**
   * 获取本地存储的手机号
   */
  static getLocalStoragePhone() {
    return window.localStorage.getItem(WFC_ACCOUNT_PHONE_KEY);
  }

  /**
   * 移除本地存储的手机号
   */
  static removeLocalStoragePhone() {
    return window.localStorage.removeItem(WFC_ACCOUNT_PHONE_KEY);
  }

  /**
   * 服务端用户信息
   * @return Promise
   */
  static fetchUserInfo() {
    // return promise
  }

  // 登录
  static doLogin(loginByPhoneForm = {}) {
    const { type, tel } = loginByPhoneForm;

    // 保存手机号到本地
    try {
      LoginFactory.storePhone(tel);
    } catch (e) {
      // do nothing
    }
    // 验证码登录方式
    if (type === 0) {
      return LoginFactory.doLoginWithPhoneCode(loginByPhoneForm);
    }
    // 密码登录方式
    if (type === 1) {
      return LoginFactory.doLoginWithPhone(loginByPhoneForm);
    }
    return new Promise().reject(new Error('Unknown login type'));
  }

  /**
   * 手机号密码登录
   * @params LoginByPhoneForm
   * @return Pormise
   */
  static doLoginWithPhone(loginByPhoneForm) {
    // 登录成功后保存token
    return LoginService.loginByPhone(loginByPhoneForm).then((res) => {
      if (res.data.code === 200) {
        const { authorization } = res.headers;
        try {
          // 保存token
          TokenStorageFactory.saveToken({ token: authorization });
          // 保存登录用户信息
          AccountFactory.setCurrentUser(res.data.data);
        } catch (e) {
          // do nothing
        }
        return Promise.resolve(res.data.data);
      }
      return Promise.reject(new Error(res.data.msg));
    });
  }

  // 手机号验证码登录
  static doLoginWithPhoneCode(loginByPhoneForm) {
    // 登录成功后保存token
    return LoginService.loginByPhoneCode(loginByPhoneForm).then((res) => {
      if (res.data.code === 200) {
        const { authorization } = res.headers;
        try {
          // 保存token
          TokenStorageFactory.saveToken({ token: authorization });
          // 保存登录用户信息
          AccountFactory.setCurrentUser(res.data.data);
        } catch (e) {
          // do nothing
        }
        return Promise.resolve(res.data.data);
      }
      return Promise.reject(new Error(res.data.msg));
    });
  }

  // 微信授权登录, openid, code 登录
  static tryLoginWithWechatCode({ wfccode }) {
    return LoginService.loginWithWechatCode({ wfccode }).then((res) => {
      if (res.data.code === 1) {
        const { authorization } = res.headers;
        try {
          // 保存token
          TokenStorageFactory.saveToken({ token: authorization });
          // 保存登录用户信息
          AccountFactory.setCurrentUser(res.data.data);
        } catch (e) {
          // do nothing
        }
        return Promise.resolve(new Error(res.data.msg));
      }
      return Promise.reject(new Error(res.data.msg));
    });
  }
}

export default LoginFactory;

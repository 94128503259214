/**
 * 登录服务
 */

import api from '../api';
import axios from '../axios';

class LoginService {
  // 手机号密码登录
  static loginByPhone(form = {}) {
    return axios.post(api.account.loginByPhone, form);
  }

  // 手机号验证码登录
  static loginByPhoneCode(form = {}) {
    return axios.post(api.account.fastLogin, form);
  }

  // 微信code 登录
  static loginWithWechatCode({ wfccode }) {
    return axios.post(api.account.loginbywfccode, { wfccode });
  }
}
export default LoginService;

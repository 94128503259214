<template lang="html">
  <div class="">
    <video class="video-bg"
      v-if="showVideoBg"
      loop="loop"
      autoplay="autoplay"
      muted="muted"
      >
      <source :src="src" type="video/mp4">
    </video>
    <div class="login-form-container" v-if="loginShow" :class="{'login-comp': showVideoBg}">
      <transition name="fadeIn">
        <div class="" v-show="showTab==='login'">
          <login-component
            @on-success="onLoginSuccess"
            @on-register-click="onRegisterClick"/>
        </div>
      </transition>
      <transition name="fadeIn">
        <div class="" v-show="showTab==='register'">
          <register-component
            @on-success="onLoginSuccess"
            @on-login-click="onLoginClick"/>
        </div>
      </transition>
    </div>
    <div class="text-center err-block" v-if="message">
      <div class="">
        {{message}}
      </div>
      <div class="" @click="$router.go(-1)">
        返回
      </div>
    </div>
  </div>
</template>

<script>
import { AccountFactory } from '../../factory/AccountFactory';
import SSOService from '../../service/SSOService';
import LoginComponent from '../account/login-component.vue';
import RegisterComponent from '../account/regist-component.vue';
import { LOGIN_BACKGROUND_VIDEO } from '../../config/index';
import { isSmartDevice } from '../../utils/device';

export default {
  components: {
    LoginComponent,
    RegisterComponent,
  },
  data: () => ({
    loginShow: false,
    showTab: 'login',
    message: '正在处理,请稍候...',
    url: '',
    ticket: '',
    src: LOGIN_BACKGROUND_VIDEO,
  }),
  computed: {
    showVideoBg() {
      return !isSmartDevice();
    },
  },
  methods: {
    init() {
      const isLoggedin = AccountFactory.isLoggedin();
      if (isLoggedin) {
        this.trySSO();
      } else {
        this.showLogin();
      }
    },
    async trySSO() {
      this.loginShow = false;
      this.message = '正在处理,请稍候...';
      // 1. 存储来源地址
      const { wfcFrom, wfcSSOClient } = this.$route.query;
      // 2.参数判断, 来源及客户端地址
      if (wfcSSOClient && wfcFrom) {
        // 3. 获取凭证
        const { ticket } = await SSOService.getTicket();
        // 拿到凭证后跳转回去
        if (ticket) {
          const url = `${wfcSSOClient}?wfcTicket=${ticket}&from=${encodeURIComponent(wfcFrom)}`;
          this.url = url;
          window.location.replace(url);
        } else {
          this.message = '';
          this.showLogin();
        }
      } else {
        this.message = 'Params Error:Require Params wfcSSOClient,wfcFrom';
      }
    },
    // 登录成功
    onLoginSuccess(res) {
      if (!res) throw new Error('@on-success function has now params');
      this.trySSO();
    },
    showLogin() {
      this.message = '';
      this.loginShow = true;
    },
    // 点击登录按钮时
    onLoginClick() {
      this.showTab = 'login';
    },
    onRegisterClick() {
      this.showTab = 'register';
    },
  },
  mounted() {
    this.init();
  },
};
</script>

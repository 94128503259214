import api from '../api';
import axios from '../axios';

class SSOService {
  /**
   * 获取登录凭证
   * @return Promise
   */
  static getTicket() {
    return axios.get(api.sso.getTicket).then((res) => {
      if (res.data.data) {
        const { ticket } = res.data.data;
        return Promise.resolve({ ticket });
      }
      return Promise.resolve({});
    });
  }

  /**
   * 使用 Ticket 换取 Token
   * @param  String  {ticket}  凭证
   * @return Promise {token}
   */
  static getTokenByTicket({ ticket }) {
    return axios.get(api.sso.getToken, { params: { ticket } }).then((res) => {
      if (res.data.data) {
        const { token } = res.data.data;
        return Promise.resolve({ token });
      }
      return Promise.reject(new Error('Fetch token failed.'));
    });
  }
}

export default SSOService;

const videos = [
  'http://cdn.wanfangche.com/video/account-bg/bg-v1.mp4',
  'http://cdn.wanfangche.com/video/account-bg/bg-v2.mp4',
  'http://cdn.wanfangche.com/video/account-bg/bg-v3.mp4',
  'http://cdn.wanfangche.com/video/account-bg/bg-v4.mp4',
  'http://cdn.wanfangche.com/video/account-bg/bg-v5.mp4',
];
const randVideoSrc = function () {
  const i = parseInt(Math.random() * videos.length, 10);
  return videos[i];
};
export const LOGIN_BACKGROUND_VIDEO = randVideoSrc();

export default {
  LOGIN_BACKGROUND_VIDEO,
};

<template lang="html">
  <div class="login-form-container" v-show="showForm">
    <div class="login-tab">
      <div class="login-tab-item" :class="{'active': tab === 0}" @click="switchTab(0)">
        <span>验证码登录</span>
      </div>
      <div class="login-tab-item t-r" :class="{'active': tab === 1}" @click="switchTab(1)">
        <span>密码登录</span>
      </div>
    </div>
    <transition name="fadeIn">
    <form class="" v-if="form" @submit.prevent="onSubmit">
      <div class="login-form-group">
        <r-input
          v-model="form.tel"
          placeholder="手机号"
          :validator="telValidator"
          @blur="onPhoneBlur"
          @focus="onPhoneFocus"
          @on-enter="OnTelInputEnter"/>
      </div>
      <div class="login-form-group" v-if="form.type !== 0">
        <r-input
          ref="passwordInput"
          type="password"
          v-model="form.passwd"
          placeholder="密码"
          @on-enter="onSubmit"
          :validator="passwordValidator"/>
      </div>
      <div class="login-form-group sms-container" v-if="form.type === 0">
          <r-input
            ref="smsCodeInput"
            class="sms-input"
            type="number"
            v-model="form.tel_code"
            @on-enter="onSubmit"
            placeholder="短信验证码"/>
        <button type="button" name="button"
          :disabled="isSendSMSButtonDisabled"
          tabindex="-1" @click="onCodeBtnClick">
          <span class="" v-if="!seconds">发送验证码</span>
          <span class="seconds" v-if="seconds">{{seconds}}s</span>
        </button>
      </div>
      <div class="login-err-message" v-if="message">
        {{message}}
      </div>
      <div class="login-form-group">
        <button type="submit"
          class="btn btn-block btn-submit"
          :disabled="submitDisabled">
          <i class="fa fa-spinner fa-spin" v-if="onSubmitting"></i>
          <i class="icon icon-check" v-show="success"></i>
          登 录
        </button>
      </div>
      <div class="login-form-group register-help">
        还没有账号? 快来<span class="text-success" @click="onRegisterClick">注册</span>
      </div>
      <!-- <div class="wechat-login-wrapper" v-if="isWechat">
        <div class="wechat-title">
          微信授权登录
        </div>
        <a @click="onWechatLoginClick">
          <img class="wechat-icon" src="http://cdn.wanfangche.com/icon/icons-wechat-4-login.png" alt="">
        </a>
      </div> -->
      <login-component-footer class="login-footer"></login-component-footer>
    </form>
    </transition>
  </div>

</template>

<script>
import {
  telValidator,
  passwordValidator,
} from '../../form/account/RegisterForm';
import { LoginFactory } from '../../factory/LoginFactory';
import { AccountService } from '../../service/AccountService';
import LoginComponentFooter from './login-component-footer.vue';
import RInput from '../../components/r-input.vue';
import { isWechat } from '../../utils/device';

export default {
  components: {
    LoginComponentFooter,
    RInput,
  },
  data: () => ({
    telValidator,
    passwordValidator,
    isWechat: isWechat(),
    showForm: true,
    tab: 0,
    timer: null,
    seconds: 0,
    message: '',
    success: false,
    onSubmitting: false,
    form: LoginFactory.createLoginByPhoneForm(),
  }),
  computed: {
    submitDisabled() {
      const canNotSubmit = !(this.form.tel) || this.form.tel.length < 10;
      return canNotSubmit;
    },
    isSendSMSButtonDisabled() {
      return this.seconds > 0 || !this.form.tel || this.form.tel.length < 10;
    },
  },
  methods: {
    init() {
      // 初始化
      const { wfccode } = this.$route.query;
      if (wfccode) {
        this.showForm = false;
        try {
          LoginFactory.tryLoginWithWechatCode({ wfccode }).then((res) => {
            setTimeout(() => {
              this.$emit('on-success', res);
            }, 100);
          }).catch((e) => {
            this.message = e.message;
            this.showForm = true;
          });
        } catch (e) {
          this.showForm = true;
        }
      }
    },
    // 选项切换
    switchTab(t) {
      this.tab = t;
      this.form.type = t;
      this.message = '';
    },
    // 手机号码输入框
    onPhoneFocus() {
      this.message = '';
    },
    onPhoneBlur() {
      try {
        LoginFactory.storePhone(this.form.tel);
      } catch (e) {
        // do nothing
      }
    },
    // 手机输入框回车事件
    OnTelInputEnter() {
      const nextTarget = this.$refs.passwordInput || this.$refs.smsCodeInput;
      nextTarget.focus();
    },
    // 表单提交
    onSubmit(e) {
      e.preventDefault();
      this.success = false;
      this.message = '';
      this.onSubmitting = true;

      const { form } = this;
      const err = form.invalid();

      if (err) {
        this.showToast(err);
        this.onSubmitting = false;
      } else {
        // 尝试登陆
        LoginFactory.doLogin(form).then((res) => {
          if (res) {
            // 跳转
          } else {
            // 提示错误
          }
          setTimeout(() => {
            this.$emit('on-success', res);
            this.success = true;
            this.onSubmitting = false;
          }, 500);
        }).catch((error) => {
          this.onSubmitting = false;
          this.message = error.message;
        });
      }
    },
    // 显示提示
    showToast(msg) {
      this.message = msg;
    },
    // 点击发送验证码按钮时
    onCodeBtnClick(e) {
      this.message = '';
      e.preventDefault();
      const err = this.form.invalidPhone();
      if (err) {
        this.message = err;
      } else {
        this.trySendCode();
      }
      // do nothing
    },
    // 尝试发送验证码
    trySendCode() {
      if (!this.timer) {
        // try send sms
        // 清空验证码
        this.form.tel_code = '';
        this.startSmsTimer();
        this.trySendSms();
      }
    },
    // 短信计时器
    startSmsTimer() {
      const MAX_SECONDS = 60;
      this.seconds = MAX_SECONDS;
      this.timer = setInterval(() => {
        if (this.seconds > 0) {
          this.seconds -= 1;
        }
        if (this.seconds <= 0) {
          clearInterval(this.timer);
          this.timer = null;
        }
      }, 1000);
    },
    // 尝试发送短信
    trySendSms() {
      const err = this.form.invalidPhone();

      if (err) {
        this.showToast(err);
        return err;
      }
      // 尝试发送短信
      const { tel } = this.form;
      // send sms
      return AccountService.sendLoginSms({ tel }).then((res) => {
        if (res.data.code === 200) {
          Promise.resolve(res.data);
        } else {
          this.message = res.data.msg;
        }
      }).catch((e) => {
        this.message = e.message;
      });
    },
    // 尝试微信登录
    onWechatLoginClick() {
      if (isWechat()) {
        this.tryWechatInnerLogin();
      } else {
        // TODO: 微信外使用微信登录
      }
    },
    tryWechatInnerLogin() {
      // 回到当前地址
      const redirect_url = encodeURIComponent(window.location.href);
      const target = `https://api.wanfangche.com/community/public/wechat/redirect?redirect_url=${redirect_url}&scope=snsapi_userinfo`;
      window.location.href = target;
    },
    // 点击注册时
    onRegisterClick() {
      this.$emit('on-register-click');
    },
  },
  created() {
    this.init();
  },
};
</script>

<style lang="css" scoped>
@import '../../css/account.css';
</style>

<template lang="html">
  <div class="text-sm text-muted text-center login-footer">
    <span>登录即视为同意</span>
    <a class="link" href="https://api.wanfangche.com/pages/agreement/">《服务协议》</a>
    <span>及</span>
    <a class="link" href="https://api.wanfangche.com/pages/privacy/">《隐私政策》</a>
  </div>
</template>

<script>
export default {
};
</script>

<style lang="css" scoped>
</style>
